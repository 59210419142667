/* eslint-disable max-params */
import interceptor from './interceptor';
import { apiUrl, adminsImageUrl } from '../environment/index';

const baseURL = `${apiUrl}/admins`;

export const adminsGetAllBenefits = (
  id = '',
  page,
  benefitType = 'all',
  keyword = '',
  approvalFilter = '',
  sponsoredFilter = false,
  categoryIds = []
  // eslint-disable-next-line max-params
) => {
  const queryParams = new URLSearchParams({
    id,
    page,
    benefitType,
    keyword,
    approvalFilter,
    sponsoredFilter,
    categoryIds: categoryIds.join(','),
  });
  return interceptor.get(`${apiUrl}/admins/benefits?${queryParams}`);
};

export const getBenefitCategories = () => {
  return interceptor.get(`${apiUrl}/admins/benefits/category`);
};

export const adminsCreateBenefit = (benefit) => {
  return interceptor.post(`${baseURL}/benefits`, benefit);
};

export const adminsUpdateBenefit = (benefit) => {
  return interceptor.put(`${baseURL}/benefits/${benefit._id}`, benefit);
};

export const adminsUpdateBenefitApproval = (id, approval) => {
  return interceptor.put(`${baseURL}/benefits/approval/${id}`, approval);
};

export const adminsDeleteBenefit = (benefitId) => {
  return interceptor.delete(`${baseURL}/benefits/${benefitId}`);
};

export const adminsCloneBenefit = (benefitId) => {
  return interceptor.post(`${baseURL}/benefits/clone/${benefitId}`);
};

export const adminsCreateBenefitCategory = (category) => {
  return interceptor.post(`${baseURL}/benefits/category`, category);
};

export const adminsUpdateBenefitCategory = (category) => {
  return interceptor.put(
    `${baseURL}/benefits/category/${category._id}`,
    category
  );
};

export const adminsDeleteBenefitCategory = (categoryId) => {
  return interceptor.delete(`${baseURL}/benefits/category/${categoryId}`);
};

export const adminsGetAllUsers = (
  keyword = '',
  page = 1,
  limit = 25,
  startDate,
  endDate,
  roleFilter
  // eslint-disable-next-line max-params
) => {
  return interceptor.get(
    `${baseURL}/users?keyword=${keyword}&page=${page}&limit=${limit}&startDate=${startDate}&endDate=${endDate}&roleFilter=${roleFilter}`
  );
};

export const adminsGetUser = (id) => {
  return interceptor.get(`${baseURL}/users/${id}`);
};

export const adminsDeleteUser = (userId) => {
  return interceptor.delete(`${baseURL}/users/${userId}`);
};

export const adminsUpdateUserPassword = (user) => {
  return interceptor.put(`${baseURL}/users/${user._id}/update-password`, user);
};

export const adminsUpdateUser = (user) => {
  return interceptor.put(`${baseURL}/users/${user._id}`, user);
};

export const adminsGetAllUserEvents = (
  page = 1,
  adminFilter = false,
  startDate = '',
  endDate = '',
  limit = 25,
  typefilter = ''
  // eslint-disable-next-line max-params
) => {
  return interceptor.get(
    `${baseURL}/user-events?page=${page}&adminFilter=${adminFilter}&startDate=${startDate}&endDate=${endDate}&limit=${limit}&typeFilter=${typefilter}`
  );
};

export const adminsGetUserEventsForUser = (
  userId,
  page = 1,
  startDate = '',
  endDate = ''
  // eslint-disable-next-line max-params
) => {
  return interceptor.get(
    `${baseURL}/user-events/by-user/${userId}?page=${page}&startDate=${startDate}&endDate=${endDate}`
  );
};

export const adminsExportUsers = (
  startDate = '',
  endDate = '',
  searchKeywords = ''
) => {
  return interceptor.get(
    `${baseURL}/export/users?startDate=${startDate}&endDate=${endDate}&searchKeywords=${searchKeywords}`,
    {
      responseType: 'blob',
      timeout: 30000,
    }
  );
};

export const adminsExportBenefits = (
  approvalFilter = '',
  searchTerm = '',
  sponsoredFilter = false,
  categoryIds = []
) => {
  const queryParams = new URLSearchParams({
    approvalFilter,
    searchTerm,
    sponsoredFilter,
    categoryIds: categoryIds.join(','),
  });
  return interceptor.get(`${baseURL}/export/benefits?${queryParams}`, {
    responseType: 'blob',
    timeout: 30000,
  });
};

export const adminsExportResourceItems = () => {
  return interceptor.get(`${baseURL}/export/resource-items`, {
    responseType: 'blob',
    timeout: 30000,
  });
};

export const adminsExportBenefitBrokenLinks = () => {
  return interceptor.get(`${baseURL}/export/benefits-broken-links`, {
    responseType: 'blob',
  });
};

export const adminsExportReferrals = (
  startDate = '',
  endDate = '',
  filterValues = [],
  searchKeywords
) => {
  return interceptor.get(`${baseURL}/export/referrals`, {
    params: {
      startDate,
      endDate,
      filterValues: filterValues.join(','),
      searchKeywords,
    },
    responseType: 'blob',
    timeout: 30000,
  });
};

export const adminsExportUserEvents = (
  startDate = '',
  endDate = '',
  typeFilter = '',
  userId = ''
  // eslint-disable-next-line max-params
) => {
  return interceptor.get(
    `${baseURL}/export/user-events?startDate=${startDate}&endDate=${endDate}&typeFilter=${typeFilter}&userId=${userId}`,
    {
      responseType: 'blob',
      timeout: 30000,
    }
  );
};

export const adminsExportUserDeletedEvents = (startDate = '', endDate = '') => {
  return interceptor.get(
    `${baseURL}/export/user-deleted-events?startDate=${startDate}&endDate=${endDate}`,
    {
      responseType: 'blob',
      timeout: 30000,
    }
  );
};

export const adminsExportUserSessionEvents = (startDate = '', endDate = '') => {
  return interceptor.get(
    `${baseURL}/export/users-sessions?startDate=${startDate}&endDate=${endDate}`,
    {
      responseType: 'blob',
      timeout: 30000,
    }
  );
};

export const addFaq = (faq) => {
  return interceptor.post(`${apiUrl}/admins/faq`, faq);
};

export const updateFaqOrder = (faq) => {
  return interceptor.put(`${apiUrl}/admins/faq`, faq);
};

export const updateFaq = (faq) => {
  return interceptor.put(`${apiUrl}/admins/faq/${faq._id}`, faq);
};

export const deleteFaq = (faqId) => {
  return interceptor.delete(`${apiUrl}/admins/faq/${faqId}`);
};

// @todo Need to refactor to accept a single object argument instead of multiple.
export const adminsGetFeedbacks = (
  page = 1,
  type = 'All',
  startDate = '',
  endDate = ''
  // eslint-disable-next-line max-params
) => {
  return interceptor.get(
    `${apiUrl}/admins/feedback?page=${page}&type=${type}&startDate=${startDate}&endDate=${endDate}`
  );
};

export const adminsUpdateFeedback = (feedback) => {
  return interceptor.put(`${apiUrl}/admins/feedback/${feedback._id}`, feedback);
};

export const deleteFeedback = (feedbackId) => {
  return interceptor.delete(`${apiUrl}/admins/feedback/${feedbackId}`);
};

export const deleteFeedbackReply = (feedbackId, replyId) => {
  return interceptor.delete(
    `${apiUrl}/admins/feedback/${feedbackId}/reply/${replyId}`
  );
};

export const updateFeedbackReply = (feedbackId, reply) => {
  return interceptor.put(
    `${apiUrl}/admins/feedback/${feedbackId}/reply/${reply._id}`,
    reply
  );
};

export const adminsAddFeedbackReply = (feedbackId, reply) => {
  return interceptor.post(
    `${apiUrl}/admins/feedback/${feedbackId}/reply`,
    reply
  );
};

export const addPage = (page) => {
  return interceptor.post(`${apiUrl}/admins/pages/${page.slug}`, page);
};

export const updatePage = (page) => {
  return interceptor.put(`${apiUrl}/admins/pages/${page.slug}`, page);
};

export const deletePage = (page) => {
  return interceptor.delete(`${apiUrl}/admins/pages/${page.slug}`);
};

export const adminsGetAllResources = (search = '') => {
  return interceptor.get(`${apiUrl}/admins/resources?search=${search}`);
};

export const createResource = (resource) => {
  return interceptor.post(`${apiUrl}/admins/resources`, resource);
};

export const updateResource = (resource) => {
  return interceptor.put(
    `${apiUrl}/admins/resources/${resource._id}`,
    resource
  );
};

export const updateResourceOrder = (resources) => {
  return interceptor.put(`${apiUrl}/admins/resources`, resources);
};

export const deleteResource = (resourceId) => {
  return interceptor.delete(`${apiUrl}/admins/resources/${resourceId}`);
};

// update a order of resourceItems
export const updateResourceItemOrder = (item) => {
  return interceptor.put(
    `${apiUrl}/admins/resources/updateItem/${item._id}`,
    item
  );
};

export const createResourceItem = (item, resourceId) => {
  return interceptor.post(
    `${apiUrl}/admins/resources/${resourceId}/items`,
    item
  );
};

export const updateResourceItem = (item, resourceId) => {
  return interceptor.put(
    `${apiUrl}/admins/resources/${resourceId}/items/${item._id}`,
    item
  );
};

export const updateResourceItemPromotionalEmails = (itemId, body) => {
  return interceptor.put(
    `${apiUrl}/admins/resources/update-resource-promotional-emails/${itemId}`,
    body
  );
};

export const deleteResourceItem = (itemId, resourceId) => {
  return interceptor.delete(
    `${apiUrl}/admins/resources/${resourceId}/items/${itemId}`
  );
};

export const getResourceItems = (search) => {
  return interceptor.get(`${apiUrl}/admins/resources/items/?search=${search}`);
};

export const getResourceItemBenefits = (itemId) => {
  return interceptor.get(`${apiUrl}/admins/resources/items/${itemId}/benefits`);
};

export const adminsGetResourceDetails = (resourceId, page = 1, search = '') => {
  return interceptor.get(
    `${apiUrl}/admins/resources/${resourceId}?page=${page}&search=${search}`
  );
};

// eslint-disable-next-line max-params
export const getReferrals = (
  page = 1,
  startDate,
  endDate,
  keyword = '',
  limit = 25,
  filterValues = []
  // eslint-disable-next-line max-params
) => {
  return interceptor.get(`${baseURL}/referral`, {
    params: {
      page,
      startDate,
      endDate,
      keyword,
      limit,
      filterValues: filterValues.join(','),
    },
  });
};

export const deleteReferral = (referralId) => {
  return interceptor.delete(`${baseURL}/referral/${referralId}`);
};

export const uploadImage = (file) => {
  return interceptor.post(`${adminsImageUrl}/upload`, file);
};

export const getUserRankStatistics = (startDate, endDate) => {
  return interceptor.get(
    `${apiUrl}/admins/statistics/user-ranks?startDate=${startDate}&endDate=${endDate}`
  );
};

export const getUserMilRelationStatistics = (startDate, endDate) => {
  return interceptor.get(
    `${apiUrl}/admins/statistics/user-military-affiliations?startDate=${startDate}&endDate=${endDate}`
  );
};

export const getUserBranchStatistics = (startDate, endDate) => {
  return interceptor.get(
    `${apiUrl}/admins/statistics/user-branches?startDate=${startDate}&endDate=${endDate}`
  );
};

export const getUserStatusStatistics = (startDate, endDate) => {
  return interceptor.get(
    `${apiUrl}/admins/statistics/user-status?startDate=${startDate}&endDate=${endDate}`
  );
};

export const getUserAttributesStatistics = (startDate, endDate) => {
  return interceptor.get(
    `${apiUrl}/admins/statistics/user-attributes?startDate=${startDate}&endDate=${endDate}`
  );
};

export const getUserStatesStatistics = (startDate, endDate) => {
  return interceptor.get(
    `${apiUrl}/admins/statistics/user-states?startDate=${startDate}&endDate=${endDate}`
  );
};

export const getUserExposureStatistics = (startDate, endDate) => {
  return interceptor.get(
    `${apiUrl}/admins/statistics/user-exposure-to-hazards?startDate=${startDate}&endDate=${endDate}`
  );
};

export const getReferralStatistics = (startDate, endDate) => {
  return interceptor.get(
    `${apiUrl}/admins/statistics/referrals?startDate=${startDate}&endDate=${endDate}`
  );
};

export const getCategoryViewsStatistics = (startDate, endDate) => {
  return interceptor.get(
    `${apiUrl}/admins/statistics/category-views?startDate=${startDate}&endDate=${endDate}`
  );
};

// New Benefit Views Route
export const getBenefitViewedStatistics = (startDate, endDate, itemId = '') => {
  return interceptor.get(
    `${apiUrl}/admins/statistics/benefits-viewed?startDate=${startDate}&endDate=${endDate}&itemId=${itemId}`
  );
};

export const getBenefitPhoneStatistics = (startDate, endDate, itemId = '') => {
  return interceptor.get(
    `${apiUrl}/admins/statistics/phone-clicks?startDate=${startDate}&endDate=${endDate}&itemId=${itemId}`
  );
};

export const getBenefitEmailStatistics = (startDate, endDate, itemId = '') => {
  return interceptor.get(
    `${apiUrl}/admins/statistics/email-clicks?startDate=${startDate}&endDate=${endDate}&itemId=${itemId}`
  );
};

export const getOrganizationOutboundClicksStatistics = (startDate, endDate) => {
  return interceptor.get(
    `${apiUrl}/admins/statistics/organization-outbound-clicks?startDate=${startDate}&endDate=${endDate}`
  );
};

export const getBenefitOutboundClicksStatistics = (
  startDate,
  endDate,
  itemId = ''
) => {
  return interceptor.get(
    `${apiUrl}/admins/statistics/benefit-outbound-clicks?startDate=${startDate}&endDate=${endDate}&itemId=${itemId}`
  );
};

export const getUserSessionsStatistics = (startDate, endDate) => {
  return interceptor.get(
    `${apiUrl}/admins/statistics/user-sessions?startDate=${startDate}&endDate=${endDate}`
  );
};

export const getResourceItemStatistics = (itemId, startDate, endDate) => {
  return interceptor.get(
    `${apiUrl}/admins/statistics/resource-items?itemId=${itemId}&startDate=${startDate}&endDate=${endDate}`
  );
};

export const getResourceItemEmailStatistics = (
  startDate,
  endDate,
  itemId = ''
) => {
  return interceptor.get(
    `${apiUrl}/admins/statistics/resource-item-email-clicks?startDate=${startDate}&endDate=${endDate}&itemId=${itemId}`
  );
};

export const getResourceItemPhoneStatistics = (
  startDate,
  endDate,
  itemId = ''
) => {
  return interceptor.get(
    `${apiUrl}/admins/statistics/resource-item-phone-clicks?startDate=${startDate}&endDate=${endDate}&itemId=${itemId}`
  );
};
